<div class="container-fluid login-component py-5">
  <ng-template #errorToastTpl let-message="message">
    {{ message }}
  </ng-template>

  <div class="row align-items-center main-login d-flex">
    @if(currentScreenSize === 'smallTwo' || currentScreenSize === 'customTablet' || currentScreenSize === 'desktop'){
      <div class="col-md-6 p-0">
        <img src="../../assets/orianna/images/login-img.png" />
      </div>
    }

    <div class="col-md-6" [ngClass]="currentScreenSize === 'smallTwo' || currentScreenSize === 'customTablet' || currentScreenSize === 'desktop'? '': 'col-12 col-md-12'">
      <div class="h2 mb-4 text-white text-center">MASUK</div>
      <form (ngSubmit)="login()">
        <div class="input-group border my-2">
          <input
            type="text"
            class="form-control bg-transparent text-white border-right-0"
            placeholder="Nama Pengguna"
            [(ngModel)]="username"
            name="username"
            (ngModelChange)="toUppercase($event)"
            aria-label="Nama Pengguna"
            autocomplete="off"
          />
          <span class="input-group-text bg-transparent border-0">
            <fa-icon [icon]="faUser" class="fa-1x"></fa-icon>
          </span>
        </div>

        <div class="input-group border mb-3">
          <input
            type="password"
            class="form-control bg-transparent text-white border-right-0"
            [type]="isPasswordVisible ? 'text' : 'password'"
            placeholder="Password"
            [(ngModel)]="password"
            name="password"
            aria-label="Password"
            autocomplete="off"
          />
          <button
            class="btn btn-outline-secondary text-white border-0 outline-0"
            type="button"
            (click)="togglePasswordVisibility()"
          >
            <fa-icon
              [icon]="isPasswordVisible ? faEyeSlash : faEye"
              class="fa-1x"
            ></fa-icon>
          </button>
        </div>

        <div class="mb-3 d-flex flex-column justify-content-center">
          <button type="submit" class="btn btn-signin my-3" [disabled]="disabledLoginButton">
            MASUK
          </button>
          <span class="text-white text-center fs-12">
            Tidak memiliki akun?
            <a href="/register" class="text-decoration-none">DAFTAR DISINI</a>
          </span>
        </div>
      </form>
    </div>
  </div>
  <!-- <div
    class="row align-items-center justify-content-center main-login-mobile d-flex d-md-none"
  >
    <div class="d-flex align-items-center w-100 img-login mb-4">
      <img src="../../../assets/orianna/images/login-img-mobile.png" />
    </div>

    <form (keydown.enter)="login()" class="mt-2">
      <div class="input-group border-bottom my-4">
        <span class="input-group-text bg-transparent border-0">
          <fa-icon [icon]="faUser" class="fa-1x"></fa-icon>
        </span>
        <input
          type="text"
          class="form-control bg-transparent text-white border-right-0"
          placeholder="Nama Pengguna"
          [(ngModel)]="username"
          name="username"
          aria-label="Nama Pengguna"
          autocomplete="off"
        />
      </div>

      <div class="input-group border-bottom mb-3">
        <span class="input-group-text bg-transparent border-0">
          <fa-icon [icon]="faLock" class="fa-1x"></fa-icon>
        </span>
        <input
          type="password"
          class="form-control bg-transparent text-white border-right-0"
          [type]="isPasswordVisible ? 'text' : 'password'"
          placeholder="Password"
          [(ngModel)]="password"
          name="password"
          aria-label="Password"
          autocomplete="off"
        />
        <button
          class="btn btn-outline-secondary text-white border-0 outline-0"
          type="button"
          (click)="togglePasswordVisibility()"
        >
          <fa-icon
            [icon]="isPasswordVisible ? faEyeSlash : faEye"
            class="fa-1x"
          ></fa-icon>
        </button>
      </div>
    </form>
    <button
      class="fixed-bottom text-uppercase border-0 outline-0 text-white w-100 btn-sign-mobile"
      (click)="login()"
    >
      masuk
    </button>
  </div> -->
  <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
</div>
