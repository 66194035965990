import { CommonModule, isPlatformBrowser } from '@angular/common';
import { Component, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '@componentpath/services/auth.service';
import { DataService } from '@componentpath/services/data.service';
import { RequestService } from '@componentpath/services/request.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faArrowCircleLeft, faCircleChevronRight, faCircleInfo, faCircleQuestion, faTrashCan } from '@fortawesome/free-solid-svg-icons';
import { MatBottomSheet, MatBottomSheetModule, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { FilterMobileComponent } from '../filter-mobile/filter-mobile.component';
import { Subscription } from 'rxjs';
import { A11y, Grid, Mousewheel } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types';
import { DepositManualMobileComponent } from '@template/green/mobile/deposit-manual-mobile/deposit-manual-mobile.component';
import { RouterExtService } from '@componentpath/services/scripts.service';

@Component({
  selector: 'app-deposit-mobile',
  standalone: true,
  imports: [CommonModule, FontAwesomeModule, MatBottomSheetModule, DepositManualMobileComponent ],
  templateUrl: './deposit-mobile.component.html',
  styleUrl: './deposit-mobile.component.scss'
})
export class DepositMobileComponent {
  subscription: Subscription = new Subscription();

  faCircleQuestion = faCircleQuestion;
  faCircleChevronRight = faCircleChevronRight;
  faTrashCan = faTrashCan;
  faCircleInfo = faCircleInfo;
  faArrowCircleLeft = faArrowCircleLeft;

  currentOptionPayment: string = 'auto';
  currentMethodPayment: string = 'vacc';
  nextStepOption: boolean = false;
  showFormPaymentAuto: boolean = false;
  showFormPaymentManual: boolean = false;
  showConfirmPayment: boolean = false;
  hideSwitchMenu: boolean = false;
  inputAmount: any = null;
  currentNominal: number = 0;
  userGroupData: Record<string, any> = {};
  bankCompanyList: Record<string, any> = {};
  depositConfig: Record<string, any> = {};
  loader: boolean = true;
  currentTitle: string = 'Deposit'

  arrayNominal: Array<number> = [10000, 20000, 50000, 100000, 500000, 1000000, 5000000, 10000000];

  public config: SwiperOptions = {
    init: true,
    modules: [A11y, Mousewheel, Grid],
    slidesPerView: 6,
    grabCursor: false,
    loop: false,
    mousewheel: true,
    freeMode: true,

  };

  footerJSONData: any = this.data.footerJSONData;
  footerData: any = [];

  currentScreenSize: string = '';
  paramsVal = '';
  prevURL: string = '';

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    public data: DataService,
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private request: RequestService,
    private bottomSheet: MatBottomSheet,
    private routerExtService: RouterExtService
  ){
    // this.openBottomSheet();
  }

  openBottomSheet(): void {
    this.bottomSheet.open(FilterMobileComponent);
  }

  backButton() {
    if (isPlatformBrowser(this.platformId)) {
      if (isPlatformBrowser(this.platformId)) {
        this.prevURL = this.routerExtService.getPreviousUrl();
        if (this.prevURL === this.router.url) {
          this.router.navigate(['/']);
        } else {
          if (this.paramsVal === '1') {
            if (this.prevURL === '/deposit?p=1') {
              this.router.navigate(['/']);
            } else {
              this.router.navigate(['/deposit']);
            }
          } else if (this.paramsVal === '2') {
            this.router.navigate([this.router.url.split('?')[0]], { queryParams: { "p": 1 } });
          } else if (this.paramsVal === '3') {
            if (this.prevURL === '/deposit?p=3') {
              this.router.navigate(['/']);
            } else {
              this.router.navigateByUrl(this.prevURL);
            }
          }else {
            this.router.navigateByUrl(this.prevURL);
          }
        }
      }
    }
  }
  
  nextButtonOption() {
    if (this.currentOptionPayment === 'auto') {
      if (this.depositConfig['virtual_account'] && this.depositConfig['qris']) {
        this.nextStepOption = true;
      } else {
        this.showFormPaymentAuto = true;
      }
    } else {
      this.showFormPaymentManual = true;
    }
  }

  nextButtonMethod() {
    this.showFormPaymentAuto = true;
  }

  selectOptionPayment(val: string) {
    this.currentOptionPayment = val;
    this.showConfirmPayment = false;
  }

  selectMethodPayment(val: string) {
    this.currentMethodPayment = val;
    this.showConfirmPayment = false;
  }

  setNominalChip(val: number) {
    this.currentNominal = val;
    this.inputAmount = this.currentNominal;
  }

  updateValueNominal(e: any) {
    let rawNominal = e.split('.').join("");
    this.inputAmount = parseInt(rawNominal);
    this.setNominalChip(this.inputAmount);
  }

  confirmDeposit() {
    this.showConfirmPayment = true;
  }

  getBankCompany() {
    this.request.getBankCompanyList(this.auth.sessionValue).subscribe({
      next: (val) => {
        // console.log(val);
        if (val['code'] === 200) {
          if (val['data'][this.userGroupData['rank']] !== undefined) {
            this.bankCompanyList = val['data'][this.userGroupData['rank']];
          } else {
            this.bankCompanyList = val['data'][1];
          }
          // console.log(this.bankCompanyList);
        }
      },
      error: (err) => {
        console.log(err)
      },
      complete: () => { }
    })
  }

  subscriptionData() {
    this.subscription.add(this.route.queryParams.subscribe((params) => {
      if (params['p']) {
        this.paramsVal = params['p'];
      }
      if (params['p'] === '2') {
        this.currentTitle = 'Tambah Rekening';
        this.hideSwitchMenu = true;
        if (!this.showFormPaymentAuto && !this.showFormPaymentManual) {
          this.router.navigate(['/deposit'])
        }
      } else {
        this.currentTitle = 'Deposit';
        this.hideSwitchMenu = true;
        if (!this.showFormPaymentAuto && !this.showFormPaymentManual) {
          this.router.navigate(['/deposit'])
        }
      }
    }));
  }

  getDepositConfig() {
    this.request.getDepositConfig(this.auth.sessionValue).subscribe({
      next: (val) => {
        if (val['code'] === 200) {
          // console.log(val);
          this.depositConfig = val['data'] as Record<string, any>;
          // this.depositConfig['status'] = true;
          // this.depositConfig['qris'] = true;
          // this.depositConfig['virtual_account'] = true;
          if (this.depositConfig['status'] === false) {
            this.showFormPaymentManual = true;
            this.currentOptionPayment = "manual";
          }
          if ((this.depositConfig['virtual_account'] && !this.depositConfig['qris'])) {
            this.currentMethodPayment = 'vacc';

          }
          if (!this.depositConfig['virtual_account'] && this.depositConfig['qris']) {
            this.currentMethodPayment = 'qris'

          }
          this.loader = false;
        }
      },
      error: (err) => {
        console.log(err)
      },
      complete: () => { }
    })
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.getBankCompany();
      this.subscriptionData();
      this.getDepositConfig();
      // console.log(this.auth.userGorupData);
      this.userGroupData = this.auth.userGorupData;
      for (const value of this.footerJSONData.records) {
        if (value.type === 'HOMEPAGE') {
          this.footerData = value.contents;
          break;
        }
      }
    }
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.subscription.unsubscribe();
    }
  }
}
