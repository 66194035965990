@if(currentScreenSize === 'xSmall' || currentScreenSize === 'small'){
    @if(filterData.length > 0){
        <div mat-dialog-title class="popup-header-mobile">
            <div class="container-title-popup-mobile">
                <fa-icon class="rotate-font" [ngClass]="currrentIndex == 0 ? 'disable-icon': ''" [icon]="faCirclePlay" (click)="currrentIndex == 0 ?  currrentIndex : currrentIndex = currrentIndex -1"></fa-icon>
                <div class="title-text-popup">
                    <div class="title-small">{{filterData[currrentIndex].keyword[0] === 'GENERAL' ? filterData[currrentIndex].keyword[1]: filterData[currrentIndex].keyword[0]}}</div>
                    <div class="title-big">{{filterData[currrentIndex].title}}</div>
                </div>
                <fa-icon [ngClass]="currrentIndex == filterData.length-1 ? 'disable-icon': ''" [icon]="faCirclePlay" (click)="currrentIndex == filterData.length-1 ?  currrentIndex : currrentIndex= currrentIndex +1"></fa-icon>
            </div>
        </div>
        <mat-dialog-content class="popup-content animation-fadein">
            <div class="image-popups">
                <img [src]="filterData[currrentIndex].asset_url">
            </div>
            <div class="container-inner" [innerHTML]="filterData[currrentIndex].content"></div>
        </mat-dialog-content>
        <mat-dialog-actions class="popup-actions">
            @if(filterData[currrentIndex].target_url !== '#' && filterData[currrentIndex].target_url !== '' && filterData[currrentIndex].target_url !== null){
                <button class="see-more" (click)="seeMore(filterData[currrentIndex].target_url)">LIHAT SELENGKAPNYA {{filterData[currrentIndex].target_url}}</button>
            }
            <button [mat-dialog-close] class="btn-logout">
                <fa-icon [icon]="faXmark" class="close-button" size="xl"></fa-icon>
            </button>
        </mat-dialog-actions>
    }
}@else {
    @if(filterData.length > 0){
        <mat-dialog-content class="popup-content-big animation-fadein">
            <div class="content-left">
                @for (item of filterData; track item;let idx = $index) {
                    <div class="container-title-left">
                        <div class="list-name" [ngClass]="currrentIndex === idx ? 'active':''" (click)="currrentIndex = idx">
                            <div class="title-small">{{filterData[currrentIndex].keyword[0] === 'GENERAL' ? filterData[currrentIndex].keyword[1]:
                                filterData[currrentIndex].keyword[0]}}</div>
                                <div class="title-big">
                                    {{item.title}}
                                </div>
                        </div>
                    </div>
                }
            </div>
            <div class="content-right">
                <button [mat-dialog-close] class="btn-logout">
                    <fa-icon [icon]="faXmark" class="close-button" size="xl"></fa-icon>
                </button>
                <div class="image-popups">
                    <img [src]="filterData[currrentIndex].asset_url">
                </div>
                <div class="container-inner" [innerHTML]="filterData[currrentIndex].content"></div>
            </div>
        </mat-dialog-content>
    }
}