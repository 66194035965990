@if(isLoggedin === true){
<div class="loggedin-component">
  <swiper-container appSwiper [config]="config" class="swiper-container">
    @for (item of selectedProvider; track item.code;) {
    <swiper-slide class="swiper-item">
      <div class="container-provider-menu">
        @for(provider of item; track provider.code;){
        <a [routerLink]="['/search']">
          <img ngSrc="{{provider.image_url}}" [title]="provider.name" [alt]="provider.name" width="150" height="150"
            loading="lazy" />
        </a>
        }
      </div>
      <div class="swiper-lazy-preloader"></div>
    </swiper-slide>
    }
  </swiper-container>
</div>
} @else{
<div class="login-component" [ngClass]="websiteJSONData.misc.login_box_style === 'ironman_alt' ? 'background-frame':websiteJSONData.misc.login_box_style === 'ironman'? 'background-frame-default':''">
  <ng-template #errorToastTpl let-message="message">
    {{ message }}
  </ng-template>
  <div class="main-login-home">
    <p class="login-title mx-auto">Sign In</p>
    <form (ngSubmit)="login()">
      <div class="input-group border-bottom">
        <span class="input-group-text bg-transparent border-0">
          <fa-icon [icon]="faUser" class="fa-1x"></fa-icon>
        </span>
        <input
          class="form-control bg-transparent text-white border-right-0"
          placeholder="Nama Pengguna"
          [(ngModel)]="username"
          name="username"
          (ngModelChange)="toUppercase($event)"
          aria-label="Nama Pengguna"
          autocomplete="off"
        />
      </div>

      <div class="input-group border-bottom my-3">
        <span class="input-group-text bg-transparent border-0">
          <fa-icon [icon]="faLock" class="fa-1x"></fa-icon>
        </span>
        <input
          type="password"
          class="form-control bg-transparent text-white border-right-0"
          [type]="isPasswordVisible ? 'text' : 'password'"
          placeholder="Password"
          [(ngModel)]="password"
          name="password"
          aria-label="Password"
        />
        <button
          class="btn btn-outline-secondary text-white border-0 outline-0"
          type="button"
          (click)="togglePasswordVisibility()"
        >
          <fa-icon
            [icon]="isPasswordVisible ? faEyeSlash : faEye"
            class="fa-1x"
          ></fa-icon>
        </button>
      </div>
      <button class="text-capitalize border-0 outline-0 text-white w-75 btn-sign-mobile sign-in" type="submit" >
        Login
      </button>
      <p class="text-no-acc">Tidak punya akun?</p>
      <button type="button" class="text-capitalize border-0 outline-0 text-white w-75 btn-sign-mobile sign-up" [routerLink]="['/register']">
        Daftar
      </button>
    </form>
  </div>
  <app-toasts aria-live="polite" aria-atomic="true"></app-toasts>
</div>
}
