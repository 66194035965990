import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatDateId',
  standalone: true,
})
export class FormatDateIdPipe implements PipeTransform {
  transform(value: number | string): string {
    return new Date(value).toLocaleDateString('id-ID', {
      weekday: 'short',
      year: '2-digit',
      month: 'short',
      day: 'numeric',
    });
  }
}
