import { CommonModule, DOCUMENT, isPlatformBrowser, KeyValuePipe, Location, NgOptimizedImage } from '@angular/common';
import { AfterViewInit, CUSTOM_ELEMENTS_SCHEMA, Component, ElementRef, HostListener, Inject, OnDestroy, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { SwiperDirective } from '@componentpath/lib/swiper.directive';
import { SplitNumberPipe } from '@componentpath/pipes/format-number-id.pipe';
import { AuthService } from '@componentpath/services/auth.service';
import { DataService } from '@componentpath/services/data.service';
import { RequestService } from '@componentpath/services/request.service';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faImages, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { faCaretDown, faCircleCheck, faCircleInfo, faCirclePlus, faCircleXmark, faCreditCard, faMagnifyingGlass, faMoneyCheckDollar, faSliders, faUser, faWallet } from '@fortawesome/free-solid-svg-icons';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { A11y, Grid, Mousewheel, Navigation, Pagination, Scrollbar } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types';
import dayjs from 'dayjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FileImageBankPipe } from '@componentpath/pipes/slug-name.pipe';
import { Subscription } from 'rxjs';
import { ToastService } from '@componentpath/services/toast.service';

@Component({
  selector: 'app-deposit-manual',
  standalone: true,
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [CommonModule, FormsModule, ReactiveFormsModule, FontAwesomeModule, MatCheckboxModule, KeyValuePipe, NgbDropdownModule, SwiperDirective, NgOptimizedImage, SplitNumberPipe, FileImageBankPipe],
  templateUrl: './deposit-manual.component.html',
  styleUrl: './deposit-manual.component.scss'
})
export class DepositManualComponent implements OnInit, OnDestroy{

  @ViewChild('swiper') swiper!: ElementRef<any>;
  
  @ViewChild('imageInput')
  imageInput!: ElementRef;

  subscription: Subscription = new Subscription();
  
  faImages = faImages;
  faTrashCan = faTrashCan;
  faSliders = faSliders;
  faWallet = faWallet;
  faCaretDown = faCaretDown;
  faUser = faUser;
  faCreditCard = faCreditCard;
  faCircleXmark = faCircleXmark;
  faCircleInfo = faCircleInfo;
  faMoneyCheckDollar = faMoneyCheckDollar;
  faCircleCheck = faCircleCheck;
  faMagnifyingGlass = faMagnifyingGlass;
  faCirclePlus = faCirclePlus;

  inputAmount: any = null;
  paymentType: string = '';
  base64ImageData: any = '';
  imageFileName: string = '';

  bankListA: Record<string, any>[] = [];
  bankListB: Record<string, any>[] = [];
  bankListC: Record<string, any>[] = [];
  bankListALL: Record<string, any>[] = [];
  bankList: Record<string, any>[] = [];
  bankListCreateA: Record<string, any>[] = [];
  bankListCreateC: Record<string, any>[] = [];
  bankListCreateALL: Record<string, any>[] = [];

  listSelectBankCompany: Record<string, any>[] = [];
  listSelectBankCompanyBank: Record<string, any>[] = [];
  listSelectBankCompanyEwall: Record<string, any>[] = [];
  listSelectBankCompanyQris: Record<string, any>[] = [];
  listSelectBankCompanyCell: Record<string, any>[] = [];
  transactionConfig: Record<string, any> = {};
  
  listPromo: Record<string, any>[] = [];

  optionsBank: Array<any> = [];
  searchedOptions: Array<any> = [];
  searchedOptionsCompany: Array<any> = [];
  searchedCreateOptions: Array<any> = [];
  listSelectBankUser: Record<string, any>[] = [];
  listSelectBankUserBank: Record<string, any>[] = [];
  listSelectBankUserEwall: Record<string, any>[] = [];
  listSelectBankUserQris: Record<string, any>[] = [];
  listSelectBankUserCell: Record<string, any>[] = [];
  promoList: Record<string, any>[] = [];
  promoSearch: Record<string, any>[] = [];
  currentCreatedBank: Record<string, any> = {};
  isCreatedBank: boolean = false

  listSelectCreateBank: Record<string, any>[] = [];
  listSelectCreateEwallet: Record<string, any>[] = [];

  currentSelectPaymentType: string = 'all';
  currentSelectCreateBank: string = 'bank';
  currentSelectedCreateBank: Record<string, any> = {};

  showNotifText: string = '';
  searchBankInput: string = '';
  inputSearchCreate: string = '';
  inputUserBankNum: string = '';
  inputUserBankName: string = '';

  showSelectPayment: boolean = false;
  showCreateBank: boolean = false;
  showPromo: boolean = false;
  disabledButton: boolean = false;
  currentPromo: Record<string,any> = {};
  selectedPromo: Record<string,any> = {};
  showError: boolean = false;
  showNotif: boolean = false;
  loader: boolean = true;

  currentDataBankAdmin: Record<string, any> = {};
  currentDataBankUser: Record<string, any> = {};
  currentSelectDataBankUser: Record<string, any> = {};
  currentSelectDataBankAdmin: Record<string, any> = {};
  currentFee: number = 0;

  // uploadImage = new FormControl('', []);
  noteDeposit = new FormControl('', []);
  searchPromo = new FormControl('', []);

  amountDepositNumber: number = 0;
  minDPAmount: number = 0;

  currentUnix = dayjs().unix();

  loadingSubmit: boolean = false;
  valueKeyboard: string = '';
  showPin: boolean = false;

  @HostListener('document:keydown', ['$event'])
  keyboardKey(event: KeyboardEvent) {
    if(this.showPin){
      this.valueKeyboard = event.key;
      const eventElement = event.target as Element;
      const numArr = eventElement.id.match(/\d+/g);
      let num = 0;
      if (numArr) {
        num = parseInt(numArr[0]);
      }
      const currentInput = this.documentHTML.getElementById(
        'pin' + num
      ) as HTMLInputElement;
      const prevInput = this.documentHTML.getElementById(
        'pin' + (num - 1)
      ) as HTMLInputElement;
      if (currentInput) {
        if (this.valueKeyboard === 'Tab' || this.valueKeyboard === 'Delete') {
          event.preventDefault();
        }
        if (
          (currentInput.value === '' || currentInput.value === undefined) &&
          prevInput &&
          this.valueKeyboard === 'Backspace'
        ) {
          event.preventDefault();
          prevInput.focus();
        }
      }
    }
  }

  formPin = {
    pina: '',
    pinb: '',
    pinc: '',
    pind: '',
    pine: '',
    pinf: '',
  };

  public config: SwiperOptions = {
    init: true,
    modules: [Navigation, Pagination, A11y, Mousewheel, Grid, Scrollbar],
    slidesPerView: 3,
    grabCursor: false,
    scrollbar: {
      enabled:true,
      draggable: true,
    },
    loop: false,
    freeMode: true,
    direction: 'vertical',
    mousewheel: {
      enabled: true,
      forceToAxis: true
    },
    pagination: false,
  };

  constructor(
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private documentHTML: Document,
    private location: Location,
    private toastService: ToastService,
    public dataService: DataService,
    private auth: AuthService,
    private router: Router,
    private route: ActivatedRoute,
    private requestService: RequestService
  ) {}

  back(){
    if (isPlatformBrowser(this.platformId)) {
      const currentUrl = window.location.href;
      const referrerUrl = document.referrer;

      const extractDomain = (url: string) => {
        try {
          const { hostname } = new URL(url);
          return hostname;
        } catch (e) {
          return null;
        }
      };

      const currentDomain = extractDomain(currentUrl);
      const referrerDomain = extractDomain(referrerUrl);

      if (currentDomain && referrerDomain && currentDomain === referrerDomain) {
        this.location.back();
      } else {
        this.router.navigate(['/']);
      }
    }
  }

  async saveImage(parent: any) {
    // const a = document.createElement("a");
    // a.href = await this.toDataURL(parent.src);
    // a.download = "myImage.png";
    // document.body.appendChild(a);
    // a.click();
    // document.body.removeChild(a);
    var link = document.createElement('a');
    link.href = parent.src;
    link.download = parent.alt;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  updateValueNominal(e: any) {
    let rawNominal = e.split('.').join("");
    this.inputAmount = parseInt(rawNominal);
    this.changeAmount();
  }

  changePayment(e:string){
    this.showSelectPayment = true;
    this.showCreateBank = false;
    this.showNotif = false;
    this.router.navigate([this.router.url.split('?')[0]], { queryParams: { "p": 1 }, queryParamsHandling: 'merge' });
    this.paymentType = e;
    if(e === 'user'){
      this.currentSelectDataBankUser = this.currentDataBankUser;
    }else {
      this.currentSelectDataBankAdmin = this.currentDataBankAdmin;
    }
  }

  selectPayment(){
    this.currentDataBankUser = this.currentSelectDataBankUser;
    this.showSelectPayment = false;
    this.showCreateBank = false;
    this.showNotif = false;
    this.paymentType = '';
    if (this.listSelectBankCompany.length > 0) {
      let resultData = this.listSelectBankCompany.find((o: any) => o.system_bank.code === this.currentSelectDataBankUser['system_bank']['code']);
      if (resultData === undefined) {
        this.currentDataBankAdmin = this.listSelectBankCompany[0];
        // this.currentSelectDataBankAdmin = this.currentDataBankAdmin;
      } else {
        this.currentDataBankAdmin = resultData;
        // this.currentSelectDataBankAdmin = this.currentDataBankAdmin;
      }
    }
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
  selectPaymentCompany(){
    // console.log(11222222)
    this.currentDataBankAdmin = this.currentSelectDataBankAdmin;
    this.showSelectPayment = false;
    this.showCreateBank = false;
    this.showNotif = false;
    this.paymentType = '';
    if (this.currentSelectDataBankAdmin['system_bank']['type'] === 3 && !this.transactionConfig['allow_cellular_promotion']) {
      // console.log('hiiiiiiiiii')
      this.resetPromo();
    }
    // if (this.listSelectBankCompany.length > 0) {
    //   let resultData = this.listSelectBankCompany.find((o: any) => o.system_bank.code === this.currentSelectDataBankUser['system_bank']['code']);
    //   if (resultData === undefined) {
    //     this.currentDataBankAdmin = this.listSelectBankCompany[0];
    //     this.currentSelectDataBankAdmin = this.currentDataBankAdmin;
    //   } else {
    //     this.currentDataBankAdmin = resultData;
    //     this.currentSelectDataBankAdmin = this.currentDataBankAdmin;
    //   }
    // }
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }
  selectPromo(){
    this.showSelectPayment = false;
    this.showCreateBank = false;
    this.showNotif = false;
    this.showPromo = false;
    this.paymentType = '';
    if(this.selectedPromo['_id']){
      this.currentPromo = this.selectedPromo;
    }
  }

  openPromoSearch(){
    this.showSelectPayment = false;
    this.showCreateBank = false;
    this.showNotif = false;
    this.showPromo = true;
    this.paymentType = '';
  }

  resetPromo(){
    this.selectedPromo = {};
    this.currentPromo = {};
  }

  resetPromoBack(){
    if(this.currentPromo['_id']){
      this.showPromo=false;
    }else {
      this.selectedPromo = {};
      this.showPromo = false;
    }
  }

  selectBankOption() {
    this.showSelectPayment = true;
    this.showCreateBank = false;
    this.showNotif = false;
    this.router.navigate([this.router.url.split('?')[0]], { queryParams: { "p": 1 }, queryParamsHandling: 'merge' });
    this.currentSelectDataBankUser = this.currentDataBankUser;
  }

  selectCreateBankOption() {
    this.showSelectPayment = false;
    this.showCreateBank = true;
    this.showNotif = false;
    this.router.navigate([this.router.url.split('?')[0]], { queryParams: { "p": 2 }, queryParamsHandling: 'merge' });
  }

  buttonBack() {
    this.showError = false;
    this.showSelectPayment = false;
    this.paymentType = '';
  }

  selectBankCreate(e: string) {
    this.currentSelectCreateBank = e;
    if (e === 'bank') {
      this.searchedCreateOptions = this.listSelectCreateBank;
    } else {
      this.searchedCreateOptions = this.listSelectCreateEwallet;
    }
    this.currentSelectedCreateBank = this.searchedCreateOptions[0];
    // console.log(this.searchedCreateOptions);
  }

  selectedCreateBank(e: any) {
    this.currentSelectedCreateBank = e;
  }

  onSearchcreateValue(e: Event) {
    const input = this.inputSearchCreate.toLowerCase();
    let typeLists: Array<any> = [];

    if (this.currentSelectCreateBank === 'bank') {
      typeLists = this.listSelectCreateBank;
    }
    if (this.currentSelectCreateBank === 'ewallet') {
      typeLists = this.listSelectCreateEwallet;
    }

    this.searchedCreateOptions = typeLists.filter((option) =>
      (option['name'].toLowerCase().includes(input))
    );

    if (!input) {
      this.searchedCreateOptions = [...typeLists];
    }
  }

  onSearchDropdownValue(event: Event): void {
    const input = this.searchBankInput.toLowerCase();
    let typeLists: Array<any> = [];

    if (this.paymentType === 'company'){
      if (this.currentSelectPaymentType === 'all') {
        typeLists = this.listSelectBankCompany;
      }
      if (this.currentSelectPaymentType === 'bank') {
        typeLists = this.listSelectBankCompanyBank;
      }
      if (this.currentSelectPaymentType === 'ewallet') {
        typeLists = this.listSelectBankCompanyEwall;
      }
      if (this.currentSelectPaymentType === 'cellular') {
        typeLists = this.listSelectBankCompanyCell;
      }
      if (this.currentSelectPaymentType === 'qris') {
        typeLists = this.listSelectBankCompanyQris;
      }

      this.searchedOptionsCompany = typeLists.filter((option) =>
        (option['system_bank']['name'].toLowerCase().includes(input) || (option['account_number']).includes(input) || (option['account_name']).includes(input))
      );

      if (!input) {
        this.searchedOptionsCompany = [...typeLists];
      }

    }else {
      // console.log(this.listSelectBankUser)
      if (this.currentSelectPaymentType === 'all') {
        typeLists = this.listSelectBankUser;
      }
      if (this.currentSelectPaymentType === 'bank') {
        typeLists = this.listSelectBankUserBank;
      }
      if (this.currentSelectPaymentType === 'ewallet') {
        typeLists = this.listSelectBankUserEwall;
      }
      if (this.currentSelectPaymentType === 'cellular') {
        typeLists = this.listSelectBankUserCell;
      }
      if (this.currentSelectPaymentType === 'qris') {
        typeLists = this.listSelectBankUserQris;
      }
      this.searchedOptions = typeLists.filter((option) =>
        (option['system_bank']['name'].toLowerCase().includes(input) || (option['account_number']).includes(input) || (option['account_name']).toLowerCase().includes(input))
      );
  
      if (!input) {
        this.searchedOptions = [...typeLists];
      }
    }
    
  }

  onSelectDropdownValue(e: any): void {
    console.log(e);
    if (this.paymentType === 'company') {

      if (e === 'all') {
        this.searchedOptionsCompany = this.listSelectBankCompany;
      }
      if (e === 'bank') {
        this.searchedOptionsCompany = this.listSelectBankCompanyBank;
      }
      if (e === 'ewallet') {
        this.searchedOptionsCompany = this.listSelectBankCompanyEwall;
      }
      if (e === 'cellular') {
        this.searchedOptionsCompany = this.listSelectBankCompanyCell;
      }
      if (e === 'qris') {
        this.searchedOptionsCompany = this.listSelectBankCompanyQris;
      }

    } else {
      if (e === 'all') {
        this.searchedOptions = this.listSelectBankUser;
      }
      if (e === 'bank') {
        this.searchedOptions = this.listSelectBankUserBank;
      }
      if (e === 'ewallet') {
        this.searchedOptions = this.listSelectBankUserEwall;
      }
      if (e === 'cellular') {
        this.searchedOptions = this.listSelectBankUserCell;
      }
      if (e === 'qris') {
        this.searchedOptions = this.listSelectBankUserQris;
      }
    }
    this.currentSelectPaymentType = e;
  }

  getBankCompanyList() {
    this.requestService.getBankCompanyAccount(this.auth.sessionValue).subscribe({
      next: (v) => {
        const value = v['data'] as Record<string, any>;
        if (value) {
          // console.log(value)
          this.currentDataBankAdmin = value['records'].find((o: any) => o.default.length > 0);
          // console.log(this.currentDataBankAdmin);

          if (this.currentDataBankAdmin === undefined) {
            this.currentDataBankAdmin = value['records'][0];
          }
          if (this.currentDataBankAdmin['system_bank']['code'] === 'qris') {
            this.currentDataBankAdmin = value['records'][1];
          }

          if (this.currentDataBankAdmin['system_bank']['transaction_fee']) {
            this.currentFee = this.currentDataBankAdmin['system_bank']['transaction_fee']
          }

          this.currentSelectDataBankAdmin = this.currentDataBankAdmin;
          this.listSelectBankCompany = value['records'];

          this.searchedOptionsCompany = this.listSelectBankCompany;
          this.listSelectBankCompanyBank = this.listSelectBankCompany.filter((option) =>
            option['system_bank']['type'] === 1
          );

          this.listSelectBankCompanyEwall = this.listSelectBankCompany.filter((option) =>
            option['system_bank']['type'] === 2
          );
          this.listSelectBankCompanyCell = this.listSelectBankCompany.filter((option) =>
            option['system_bank']['type'] === 3
          );
          this.listSelectBankCompanyQris = this.listSelectBankCompany.filter((option) =>
            option['system_bank']['type'] === 4
          );

          this.selectedBankUser(this.currentDataBankUser); /// set default based bank user
          this.loader = false;
        }
      },
      error: (error) => {
        console.log(error)
      },
      complete: () => {}
    })
  }

  getBankCompany() {
    this.loader = true;
    this.bankListA = [];
    this.bankListC = [];
    this.bankListALL = [];
    let value = this.dataService.bankRegisterJSONData;
    for (let i = 0; i < value['records'].length; i++) {
      this.bankListALL.push(value['records'][i]);
      if (value['records'][i]['type'] !== 1) {
        this.bankListA.push(value['records'][i]);
      } else {
        this.bankListC.push(value['records'][i]);
      }
    }

    this.requestService.getBankPlayerList(this.auth.sessionValue, this.auth.userProfileData['_id'] as string).subscribe({
      next: (v) => {
        // console.log(v);
        const value = v as Record<string, any>;
        if (value['code'] === 200) {
          this.bankListB = value['data']['records'];
          this.bankList = this.bankListB;
          this.bankListCreateA = this.bankListA.filter(({ _id: id1 }) => !this.bankList.some(({ bank: id2 }) => id2 === id1));
          this.bankListCreateC = this.bankListC.filter(({ _id: id1 }) => !this.bankList.some(({ bank: id2 }) => id2 === id1));
          this.bankListCreateALL = this.bankListALL.filter(({ _id: id1 }) => !this.bankList.some(({ bank: id2 }) => id2 === id1));
          this.bankListCreateC.sort(function (a: any, b: any) {
            return a.name.localeCompare(b.name)
          });
          this.bankListCreateALL.sort(function (a: any, b: any) {
            return a.name.localeCompare(b.name)
          });
          
          if (this.isCreatedBank) {
            let indexSelected = this.bankList.findIndex(x => (x['bank'] === this.currentCreatedBank['bank'] && x['account_number'] === this.currentCreatedBank['account_number']));
            this.currentDataBankUser = this.bankList[indexSelected];
          } else {
            if (this.bankList[0]['system_bank']['code'] !== 'qris') {
              this.currentDataBankUser = this.bankList[0];
            } else {
              this.currentDataBankUser = this.bankList[1];
            }
          }
          this.currentSelectDataBankUser = this.currentDataBankUser;

          this.listSelectBankUser = this.bankList.filter((option) =>
            option['system_bank']['type'] !== 3 && option['system_bank']['type'] !== 4
          );
          this.searchedOptions = this.listSelectBankUser;
          this.currentSelectedCreateBank = this.bankListCreateC[0];

          this.listSelectCreateBank = this.bankListCreateC.filter((option) =>
            option['type'] === 1
          );
          if (this.transactionConfig['allow_add_ewallet']){
            this.listSelectCreateEwallet = this.bankListA.filter((option) =>
              option['type'] === 2
            );
          } else {
            this.listSelectCreateEwallet = this.bankListCreateA.filter((option) =>
              option['type'] === 2
            );
          }

          this.searchedCreateOptions = this.listSelectCreateBank;

          this.listSelectBankUserBank = this.bankList.filter((option) =>
            option['system_bank']['type'] === 1
          );
          this.listSelectBankUserEwall = this.bankList.filter((option) =>
            option['system_bank']['type'] === 2
          );
          // this.listSelectBankUserCell = this.bankList.filter((option) =>
          //   option['system_bank']['type'] === 3
          // );
          // this.listSelectBankUserQris = this.bankList.filter((option) =>
          //   option['system_bank']['type'] === 4
          // );
          // console.log(this.bankList);
          // console.log(this.bankListCreateA);
          // console.log(this.bankListCreateC);
          // console.log(this.bankListCreateALL);
          // if (isModal) {
          //   let indexSelected = this.bankList.findIndex(x => (x['bank'] === this.currentCreatedBank['bank'] && x['account_number'] === this.currentCreatedBank['account_number']));
          //   this.currentDataBankUser = this.bankList[indexSelected];
          //   this.showSelectBankCompany = false;
          //   this.showSelectBankUser = false;
          //   this.showSelectPromo = false;
          // } else {
          //   if (this.bankList[0]['system_bank']['code'] !== 'qris') {
          //     this.currentDataBankUser = this.bankList[0];
          //   } else {
          //     this.currentDataBankUser = this.bankList[1];
          //   }
          //   this.listSelectBankUser = this.bankList;
          // }
          
          if(this.isCreatedBank){
            let indexSelected = this.bankList.findIndex(x => (x['bank'] === this.currentCreatedBank['bank'] && x['account_number'] === this.currentCreatedBank['account_number']));
            this.currentDataBankUser = this.bankList[indexSelected];
          } else {
            if (this.bankList[0]['system_bank']['code'] !== 'qris') {
              this.currentDataBankUser = this.bankList[0];
            } else {
              this.currentDataBankUser = this.bankList[1];
            }
          }
          // this.listSelectBankUser = this.bankList.filter((option) =>
          //   option['system_bank']['type'] !== 3 && option['system_bank']['type'] !== 4
          // );

          this.getBankCompanyList();
        }
      },
      error: (error) => {
        console.log(error)
      },
      complete: () => {
      }
    })
  }

  getPromoList(){
    this.requestService.getPromoList().subscribe({
      next: (v) => {
        // console.log(v);
        const value = v as Record<string, any>;
        if (value['data']) {
          this.promoList = value['data'];
          this.promoSearch = this.promoList;
        }
      },
      error: (e) => {
        console.log(e);
      },
      complete: () => { }
    })
  }

  inputSearchPromo() {
    let codePromo = this.searchPromo.value;
    if (codePromo !== '' && codePromo !== undefined && codePromo !== null) {
      this.requestService.getPromoCode(codePromo).subscribe({
        next: (v) => {
          // console.log(v);
          const value = v as Record<string, any>;
          this.promoSearch = [];
          this.promoSearch.push(value['data']);
        },
        error: (e) => {
          console.log(e);
          this.showNotif = true;
          this.showNotifText = e.error.messages[0].message;
        },
        complete: () => { }
      })
    } else {
      this.promoSearch = this.promoList;
    }
  }
  

  createBankAcc() {
    this.disabledButton = true;
    if (this.inputUserBankNum !== '' && this.inputUserBankNum !== undefined && this.inputUserBankName !== '' && this.inputUserBankName !== undefined) {

      this.showNotif = false;
      this.requestService.createAccountBank(this.auth.sessionValue, this.currentSelectedCreateBank['_id'], this.inputUserBankName as string, this.inputUserBankNum as string, this.auth.userProfileData['_id'] as string).subscribe({
        next: (v) => {
          const value = v as Record<string, unknown>;
          // console.log(value);
          if (value['code'] === 200) {
            const data = value['data'] as Record<string, any>;
            this.loader = true;
            this.router.navigate(['/deposit']);
            this.isCreatedBank = true;
            this.currentCreatedBank = data;
            this.getBankCompany();
            this.showCreateBank = false;
            this.disabledButton = false;
            // this.toastService.showToasts(value['messages'] as Record<string, string | number | boolean>[]);
            // this.dialogRef.close(value['data']);
          }
        },
        error: (e) => {
          console.log(e);
          this.disabledButton = false;
          if (e.error['messages']) {
            this.showNotif = true;
            this.showNotifText = e.error.messages[0].message;
            // this.toastService.showToasts(e.error['messages'] as Record<string, string | number | boolean>[])
          }
          else {
            // this.toastService.showToast({ message: 'Error communication with API - Please check your internet connection', status: 'error' });
          }
          this.disabledButton = false;
        },
        complete: () => { }

      })

    } else {
      this.showNotif = true;
      this.showNotifText = 'Error Input, Mohon Check Nomor Rekening Anda';
      this.disabledButton = false;
    }
  }

  changeAmount() {
    if (this.inputAmount !== undefined) {
      this.amountDepositNumber = parseInt(this.inputAmount);

      let fixFee = 0;
      let percentFee = 0;
      if (this.currentDataBankAdmin['system_bank']['transaction_fee']) {
        fixFee = parseInt(this.currentDataBankAdmin['system_bank']['transaction_fee']);
      }
      if (this.currentDataBankAdmin['system_bank']['transaction_fee_percentage']) {
        percentFee = parseFloat(this.currentDataBankAdmin['system_bank']['transaction_fee_percentage']);
      }

      let percentFeeValue = percentFee * parseInt(this.inputAmount);
      this.currentFee = percentFeeValue > fixFee ? percentFeeValue : fixFee;
    }
  }

  selectedBankCompany(val: any) {
    // this.currentDataBankAdmin = val;
    this.currentSelectDataBankAdmin = val;
    // if (this.currentSelectDataBankAdmin['system_bank']['type'] === 3 && !this.transactionConfig['allow_cellular_promotion']){
    //   console.log('hiiiiiiiiii')
    //   this.resetPromo();
    // }
    // if (this.currentDataBankAdmin['system_bank']['transaction_fee']) {
    //   this.currentFee = this.currentDataBankAdmin['system_bank']['transaction_fee']
    // }
    // this.changeAmount();
    // if (isPlatformBrowser(this.platformId)) {
    //   window.scrollTo({ top: 0, behavior: 'smooth' });
    // }
  }
  selectedBankUser(val: any) {
    this.currentSelectDataBankUser = val;
    // this.currentDataBankUser = val;
    // if (this.listSelectBankCompany.length > 0) {
    //   let resultData = this.listSelectBankCompany.find((o: any) => o.system_bank.code === this.currentDataBankUser['system_bank']['code']);
    //   if (resultData === undefined) {
    //     this.currentDataBankAdmin = this.listSelectBankCompany[0];
    //   } else {
    //     this.currentDataBankAdmin = resultData;
    //   }
    // }
    // if (isPlatformBrowser(this.platformId)) {
    //   window.scrollTo({ top: 0, behavior: 'smooth' });
    // }
  }

  getBase64(file: any, resize: boolean, sizeKB: number) {
    var scale = 0.7;

    if (sizeKB > 10000) {
      scale = 0.3;
    }
    if (sizeKB > 5000 && sizeKB < 10000) {
      scale = 0.4;
    }
    if (sizeKB > 3000 && sizeKB < 5000) {
      scale = 0.5;
    }

    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      if (resize) {
        this.base64Resize(reader.result, scale);
      } else {
        this.base64ImageData = reader.result;
      }
    };
    reader.onerror = function (error) {
      console.log('Error: ', error);
    };
  }

  base64Resize(sourceBase64: any, scale: any) {

    const _scale = scale;
    var img = document.createElement('img');
    img.setAttribute("src", sourceBase64);

    img.onload = () => {
      var canvas = document.createElement('canvas');
      canvas.width = img.width * _scale;
      canvas.height = img.height * _scale;

      var ctx = canvas.getContext("2d");
      var cw = canvas.width;
      var ch = canvas.height;
      var maxW = img.width * _scale;
      var maxH = img.height * _scale;

      var iw = img.width;
      var ih = img.height;
      var scl = Math.min((maxW / iw), (maxH / ih));
      var iwScaled = iw * scl;
      var ihScaled = ih * scl;
      canvas.width = iwScaled;
      canvas.height = ihScaled;
      ctx!.drawImage(img, 0, 0, iwScaled, ihScaled);
      const newBase64 = canvas.toDataURL("image/jpeg", scl);
      // console.log(newBase64);
      this.base64ImageData = newBase64;
      return newBase64;
    }
  }

  onSelectFile(e: any) {
    // console.log(e)
    if (e.target.files.length > 0) {
      console.log(e)
      this.imageFileName = e.target.files[0].name;
      let sizeKB = e.target.files[0].size / 1024;
      let fileType = e.target.files[0].type;
      // console.log('File Type :', fileType);
      // console.log('Size :', e.target.files[0].size / 1024);
      // console.log(e.target.files[0].name)
      if (sizeKB > 1500) {
        this.getBase64(e.target.files[0], true, sizeKB);
      } else {
        if (fileType !== "image/png" && fileType !== "image/jpeg") {
          // this.uploadImage.setErrors({ 'invalidFileType': true });
          // this.uploadImage.markAllAsTouched();
          // console.log(this.uploadImage.touched)
        } else {
          this.getBase64(e.target.files[0], false, sizeKB);
        }
      }
    } else {
      this.imageFileName = 'File Not Selected';
      this.base64ImageData = '';
    }

  }

  clearFileImage() {
    this.imageInput.nativeElement.value = "";
    this.base64ImageData = "";
    this.imageFileName = "";

  }

  checkAllInput(): boolean {
    if (this.inputAmount !== undefined && this.inputAmount !== '' && this.inputAmount !== null) {
      return true
    } else {
      // if (!this.uploadImage.valid) {
      //   this.uploadImage.setErrors({ 'invalidFileType': true });
      //   this.uploadImage.markAllAsTouched();
      // }
      return false
    }
  }

  inputPIN(i: number) {
    const nextIndex = i + 1;
    const prevIndex = i - 1;
    const currentInput = this.documentHTML.getElementById(
      'pin' + i
    ) as HTMLInputElement;
    const nextInput = this.documentHTML.getElementById(
      'pin' + nextIndex
    ) as HTMLInputElement;
    const prevInput = this.documentHTML.getElementById(
      'pin' + prevIndex
    ) as HTMLInputElement;
    const lastInput = this.documentHTML.getElementById(
      'pin' + 6
    ) as HTMLInputElement;
    if (
      nextInput &&
      currentInput.value !== '' &&
      this.valueKeyboard !== 'Backspace'
    ) {
      nextInput.focus();
    }
    if (prevInput && this.valueKeyboard === 'Backspace') {
      prevInput.focus();
    }
    if (
      currentInput === lastInput &&
      (currentInput.value !== '' || currentInput.value !== undefined) &&
      this.valueKeyboard !== 'Backspace'
    ) {
      // console.log(lastInput.value);
      this.formPin.pinf = lastInput.value;
    }

    if (i === 6) {
      this.submitPIN();
    }
    return;
  }

  checkPin(){
    this.formPin.pina = '';
    this.formPin.pinb = '';
    this.formPin.pinc = '';
    this.formPin.pind = '';
    this.formPin.pine = '';
    this.formPin.pinf = '';

    this.showNotif = false;
    this.showNotifText = '';
    this.showPin = true;
  }

  submitDeposit() {
    this.disabledButton = true;

    if (this.checkAllInput()) {

      let shortDate = dayjs().format('DD-MM-YYYY');
      let currentDateTime = dayjs().format('HH:mm');
      // let shortDateTime = dayjs(shortDate + ' ' + this.timeDeposit.value, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY HH:mm:ss');
      let shortDateTime = dayjs(shortDate + ' ' + currentDateTime, 'DD-MM-YYYY HH:mm').format('DD-MM-YYYY HH:mm:ss');

      let noteValue = this.noteDeposit.value;

      if (noteValue === null) {
        noteValue = '';
      }

      if (this.base64ImageData === undefined) {
        this.base64ImageData = '';
      }

      let promoID = '';
      let promoType = '';
      if (this.currentPromo['_id']) {
        promoID = this.currentPromo['_id'];
        promoType = this.currentPromo['type'];
      }

      // console.log('CALLED')

      this.requestService.createDeposit(this.auth.sessionValue, this.currentDataBankUser['_id'], this.currentDataBankAdmin['_id'], this.inputAmount as string, shortDateTime, this.base64ImageData, noteValue, promoType, promoID).subscribe({
        next: (v) => {
          const value = v as Record<string, unknown>;
          if (value['code'] === 200) {
            const data = value['data'] as Record<string, any>;
            const messagesResponse = value['messages'] as Record<string, string | number | boolean>[];
            if (messagesResponse.length === 0) {
            }
            this.router.navigate(['/transaction']);
          }
        },
        error: (e) => {
          if (e.error['messages']) {
            console.log(e.error)
            this.showNotif = true;
            this.showNotifText = e.error.messages[0].message;
          }
          else {
          }
          this.disabledButton = false;
        },
        complete: () => {
          this.disabledButton = false;
        }
      })
    } else {
      console.log('ERROR CHECK')
      this.showNotif = true;
      this.showNotifText = 'Error Input, Mohon check jumlah deposit anda';
      this.disabledButton = false;
    }
  }

  subscriptionData(){
    this.subscription.add(this.route.queryParams.subscribe((params) => {
      params;
      // console.log(params);
      if (params['p'] === '1') {
        // this.loader = true;
        this.showSelectPayment = true;
        this.showCreateBank = false;
        this.showNotif = false;
        // this.loader = false;
      }
      else if (params['p'] === '2') {
        if (this.transactionConfig['allow_add_bank_account']){
          // this.loader = true;
          this.showSelectPayment = false;
          this.showCreateBank = true;
          this.showNotif = false;
          // this.loader = false;
        } else {
          this.showCreateBank = false;
          this.router.navigate(['/deposit']);
        }
      }
      else {
        // this.router.navigate(['/withdraw']);
        this.showCreateBank = false;
        this.showSelectPayment = false;
        this.showError = false;
      }
    }));
  }

  submitPIN() {
    this.loadingSubmit = true;
    const userSecurity = this.auth.userSecurityData['pin'].toString();
    const arrFromPIN = Object.values(this.formPin);
    const PINvalue = arrFromPIN.join('');
    if (userSecurity === PINvalue) {
      this.loadingSubmit = false;
      this.showPin = false;
      this.showNotif = false;
      this.showNotifText = '';
      this.submitDeposit();
    } else {
      this.showNotif = true;
      this.showNotifText = 'PIN yang anda input salah';
      this.loadingSubmit = false;
    }
  }

  ngOnInit(){
    if (isPlatformBrowser(this.platformId)) {
      // this.optionsBank = this.dataService.bankRegisterJSONData['records'] as any[];
      // this.searchedOptions = this.optionsBank;
      this.getPromoList();
      this.getBankCompany();
      this.subscriptionData();
      this.minDPAmount = this.dataService.transactionConfigJSONData['min_deposit_amount'];
      this.inputUserBankName = this.auth.userProfileData['first_name'] + (this.auth.userProfileData['last_name'] ? ' ' + this.auth.userProfileData['last_name'] : '');
      this.transactionConfig = this.dataService.transactionConfigJSONData;
    }
  }

  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.subscription.unsubscribe();
    }
  }

}
