import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'slugName',
  standalone: true
})
export class SlugNamePipe implements PipeTransform {

  transform(value: string): string {
    let lowerCaseValue = value.toLowerCase();
    return lowerCaseValue
      .trim()
      .replace(/[^\w\s-]/g, '')
      .replace(/[\s_-]+/g, '-')
      .replace(/^-+|-+$/g, '');
  }

}
@Pipe({
  name: "orderBy",
  standalone: true
})
export class OrderByPipe implements PipeTransform {
  transform(array: any, sortBy: string, order?: string): any[] {
    const sortOrder = order ? order : 'asc'; // setting default ascending order
    return array.sort((a:any, b:any) => {
      return a[sortBy] < b[sortBy] ? (sortOrder === 'asc' ? -1 : 1) : (sortOrder === 'asc' ? 1 : -1);
    })
 
  }
}
@Pipe({
  name: 'removeDash',
  standalone: true
})
export class RemoveDashPipe implements PipeTransform {

  transform(value: string): string {
    let lowerCaseValue = value;
    return lowerCaseValue
      .replace('-', ' ');
  }

}
@Pipe({
  name: 'enCodeUri',
  standalone: true
})
export class EncodeUriPipe implements PipeTransform {

  transform(value: string): string {
    let lowerCaseValue = value;
    return encodeURIComponent(JSON.stringify([lowerCaseValue]));
  }

}

@Pipe({ name: 'tagName', standalone: true })
export class TagNamePipe implements PipeTransform {
  constructor() { }
  transform(value: string): string {
    if (value === 'BUYIN') {
      return 'BUY IN'
    } else if (value === 'LINKWIN') {
      return 'LINK & WIN'
    } else {
      return value;
    }
  }
}

@Pipe({ name: 'fileImageBank', standalone: true })
export class FileImageBankPipe implements PipeTransform {
  constructor() { }
  transform(value: Record<string, any>): string {
    return value['qr_image'] ? value['_id'] + value['qr_image'].match(/\.\w{3,4}($|\?)/)[0] : '';
  }
}
